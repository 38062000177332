// pages/InstalliOS.js
import React from 'react';
import { Typography, Container } from '@mui/material';

const InstalliOS = () => {
  return (

    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Typography variant="h3" gutterBottom>Установка на iOS</Typography>
      <Typography variant="body1">Для установки этого приложения нажмите "Поделиться".</Typography>
      <img src="/ios-share.jpg" alt="iOS Share" style={{ width: '100%' }} />
      <Typography variant="body1">Выберите "На экран 'Домой'".</Typography>
      <img src="/ios-add-to-home.jpg" alt="iOS Add to Home" style={{ width: '100%' }} />
    </Container>
  );
}

export default InstalliOS;