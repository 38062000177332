// pages/ListingDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListing } from '../slices/listingsSlice';
import { verifyPushyRegistration } from '../utils/pushy';
import Pushy from 'pushy-sdk-web';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Alert,
  IconButton,
  Stack,
  CircularProgress,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  CardHeader,
  CardMedia,
  CardActions,
  Link,
  Collapse
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditIcon from '@mui/icons-material/Edit';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Offers from '../components/Offers';
import Ratings from '../components/Ratings';
import { jwtDecode } from 'jwt-decode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

const ListingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listing = useSelector((state) =>
    state.listings.listings.find((l) => l.id === id)
  );
  const [registered, setRegistered] = useState(Pushy.isRegistered());
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));

  const userId = () => authToken && jwtDecode(authToken).sub;

  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    Pushy.validateDeviceCredentials().then(() => setRegistered(true)).catch(() => setRegistered(false));
    dispatch(fetchListing({ id, authToken, navigate }));
  }, [id, authToken, navigate, dispatch]);

  const handleCloseListing = async (listingId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings/${listingId}/close`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to close listing');
      }

      dispatch(fetchListing({ id, authToken, navigate }));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleConfirmCloseListing = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleShare = () => {
    const url = `${process.env.PUBLIC_URL}/listing/${id}`;
    if (navigator.share) {
      navigator.share({
        title: listing?.description || 'Спецзаказ',
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      // Fallback for browsers that do not support the Web Share API
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  if (!listing) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h3">Объявление</Typography>
          <Stack alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress />
          </Stack>
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack spacing={1}>
        <Typography variant="h3" gutterBottom>Объявление</Typography>
        <Card key={listing.id}>

          <CardHeader
            avatar={<Avatar
              sx={{ bgcolor: 'primary.light' }}
              src={listing.photoUrl}
              alt={`${listing.name}`} />}

            title={`${listing.name}`}
            subheader={listing.ratingsCount > 0
              ? `Рекомендуют ${listing.avarageRating.toFixed(2) * 100}% (${listing.ratingsCount})`
              : 'Нет отзывов'}
            action={<Typography variant="caption" color="text.secondary">
              {new Date(listing.createdAt).toLocaleDateString()}
            </Typography>}
          />

          {listing?.photos?.length > 0 &&
            <Slider {...settings}>
              {listing.photos.map((item, index) => (
                <CardMedia
                  key={index}
                  component="img"
                  image={`${process.env.REACT_APP_BE_URL}${item.filePath}`}
                  alt={`image-${index}`} />
              ))}
            </Slider>
          }

          <CardContent>
            <Typography variant="h6" color="text.primary">
              {listing.title}
            </Typography>
            <Stack direction={'row'} alignItems="baseline" spacing={1}>
              {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                {listing.askedPrice} руб.
              </Typography>}
              <Typography variant="subtitle1" color="text.primary">
                {listing.isProvider ? 'Предоставляю услугу' : 'Воспользуюсь услугой'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {listing.location}
              </Typography>
            </Stack>

            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {listing.description}
            </Typography>

            {listing.isClosed && <Alert sx={{ mt: 1 }} severity="info">Объявление закрыто</Alert>}
            {error && (error.includes('Add to Home') ?
              <Alert sx={{ mt: 1 }} severity="info">Для включения уведомлений, <Link href="/install-ios">установите приложение на домашний экран</Link>.</Alert> :
              <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>)}
          </CardContent>

          <CardActions>

            {!listing.isClosed && (listing.creatorId === userId() || listing.offers?.some(o => o.creatorId === userId() && o.isAccepted)) &&
              <Button variant='contained' size="small" onClick={handleConfirmCloseListing}>Закрыть</Button>
            }

            {listing.phoneNumber && (<>
              <Link href={`tel:${listing.phoneNumber}`} variant="body1">+{listing.phoneNumber}</Link>
              <Link href={`https://wa.me/${listing.phoneNumber}?text=Из%20profisfera.ru/listing/${listing.id}.%20`} variant="body1">WhatsApp</Link>
            </>)}

            <Box sx={{ flexGrow: 1 }} />

            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardActions>
              <IconButton aria-label="share" onClick={handleShare} >
                <ShareIcon />
              </IconButton>
              {registered ?
                <IconButton aria-label="notifications" disabled>
                  <NotificationsActiveIcon />
                </IconButton> :
                <IconButton aria-label="notifications" onClick={() =>
                  verifyPushyRegistration(navigate).then(() => {
                    setRegistered(Pushy.isRegistered());
                  }).catch((error) => {
                    setError(error.message);
                  })} >
                  <NotificationsIcon />
                </IconButton>}

              {!listing.isClosed && listing.creatorId === userId() &&
                <IconButton aria-label="edit" onClick={() => navigate(`/create-listing/${id}`)} >
                  <EditIcon />
                </IconButton>}
            </CardActions>
          </Collapse>
        </Card>
        <Box sx={{ pt: 1 }} />
        <Offers listing={listing} />
        <Box sx={{ pt: 1 }} />
        <Ratings listing={listing} />
      </Stack>
      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}>
        <DialogTitle>Подтверждение закрытия</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы уверены, что хотите закрыть это объявление? Это действие нельзя будет отменить.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleConfirmDialogClose} color="primary">
            Отмена
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleCloseListing(listing.id);
              handleConfirmDialogClose();
            }}
            color="secondary"
            autoFocus>
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ListingDetail;
