// pages/Listings.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllListings } from '../slices/listingsSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Card, CardContent, CardMedia, Avatar, Alert, Tabs, Tab, Container, CardHeader, CardActionArea } from '@mui/material';
import InstallPWA from '../components/InstallPWA';

const Listings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listings, error } = useSelector((state) => state.listings);

  const [isVisible, setIsVisible] = useState(true);
  const [isProvider, setIsProvider] = useState(() => {
    return localStorage.getItem('isProvider') !== 'false';
  });

  useEffect(() => {
    const fetchListings = () =>
      isVisible && dispatch(fetchAllListings({ navigate, isProvider }));

    fetchListings();
  }, [dispatch, navigate, isVisible, isProvider]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    localStorage.setItem('isProvider', newValue === 0);
    setIsProvider(newValue === 0);
  };

  return (
    <>
      <InstallPWA />
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          {error && <Alert severity="error">{error}</Alert>}
          <Typography variant="h3" gutterBottom>Объявления</Typography>
          <Tabs
            value={isProvider ? 0 : 1}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto">
            <Tab label="Предоставляю услугу" />
            <Tab label="Воспользуюсь услугой" />
          </Tabs>

          {
            listings.map(listing => (
              <Card
                key={listing.id}
                onClick={() => navigate(`/listing/${listing.id}`)}>

                <CardActionArea>

                  <CardHeader
                    avatar={<Avatar
                      sx={{ bgcolor: 'primary.light' }}
                      src={listing.photoUrl}
                      alt={`${listing.name}`}
                    />}

                    title={`${listing.name}`}
                    subheader={listing.ratingsCount > 0
                      ? `Рекомендуют ${listing.avarageRating.toFixed(2) * 100}% (${listing.ratingsCount})`
                      : 'Нет отзывов'}
                    action={<Typography variant="caption" color="text.secondary">
                      {new Date(listing.createdAt).toLocaleDateString()}
                    </Typography>}
                  />

                  {
                    listing.photos.length !== 0 &&
                    <CardMedia
                      component="img"
                      image={`${process.env.REACT_APP_BE_URL}${listing.photos[0].filePath}`}
                      alt={listing.description}
                      sx={{ maxHeight: '25vh' }}
                    />
                  }

                  <CardContent>
                    <Typography variant="h6" color="text.primary">
                      {listing.title}
                    </Typography>
                    <Stack direction={'row'} alignItems="baseline" spacing={1}>
                      {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                        {listing.askedPrice} руб.
                      </Typography>}
                      <Typography variant="body2" color="text.secondary">
                        {listing.location}
                      </Typography>
                    </Stack>

                    <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                      {listing.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
        </Stack>

      </Container>
    </>
  );
};

export default Listings;
