// App.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Listings from './pages/Listings';
import Login from './pages/Login';
import Pushy from 'pushy-sdk-web';
import { upsertListing } from './slices/listingsSlice';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import CreateListing from './pages/CreateListing';
import Profile from './pages/Profile';
import ListingDetail from './pages/ListingDetail';
import User from './pages/User';
import Privacy from './pages/Privacy';
import InstalliOS from './pages/InstalliOS';
import EditProfile from './pages/EditProfile';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    Pushy.setNotificationListener((data) => {
      console.log('Received notification: ' + JSON.stringify(data));

      if (data.extra) {
        const extra = JSON.parse(data.extra);

        if (extra.listing)
          dispatch(upsertListing(extra.listing));
      }
    });
  }, [dispatch]);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#ff5722',
      },
      secondary: {
        main: '#03a9f4',
        contrastText: '#fff',
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ bgcolor: '#ebedef' }} style={{ paddingBottom: '120px' }}>
        <Routes>
          <Route path="/" element={<Listings />} />
          <Route path="/create-listing/:id?" element={<CreateListing />} />
          <Route path="/listing/:id" element={<ListingDetail />} />
          <Route path="/user/:userId" element={<User />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/install-ios" element={<InstalliOS />} />
        </Routes>
        <Navbar />
      </Box>
    </ThemeProvider>
  );
};

export default App;
