// pages/Login.js
import React, { useEffect, useState } from 'react';
import PhoneInput from '../components/PhoneInput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Typography, Link, Alert, TextField, Button, MobileStepper, Stack, Avatar } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);
  const [name, setName] = useState('');
  const [photoBlob, setPhotoBlob] = useState(null); // Store the resized image as a Blob
  const [imageSrc, setImageSrc] = useState(null);
  const [searchParams] = useSearchParams();
  const to = searchParams.get('to');
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    setError(null);

    if (activeStep === 0) {
      navigate('/');
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    let deviceToken = localStorage.getItem('pushyToken');
    let authToken = localStorage.getItem('authToken');

    localStorage.removeItem('authToken');
    localStorage.removeItem('pushyToken');
    localStorage.removeItem('pushyTokenAuth');

    authToken && deviceToken && fetch(`${process.env.REACT_APP_BE_URL}/be/devicetokens/${deviceToken}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
  }, []);

  const navigate = useNavigate();
  const phoneNumberNormalized = (phone) => phone.replace(/\D/g, '');

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {

      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/auth/code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: phoneNumberNormalized(phoneNumber) }),
      });

      const text = await response.text();

      if (!response.ok) {
        setError(text);
        return;
      }

      setActiveStep(1);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/auth/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phoneNumber: phoneNumberNormalized(phoneNumber), otp: verificationCode }),
      });

      if (!response.ok) {
        const text = await response.text();
        setError(text);
        return;
      }

      const data = await response.json();
      localStorage.setItem('authToken', data.token);

      if (data.name)
        navigate(to ? to : '/');
      else
        setActiveStep(2);

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setActiveStep(0);
        return;
      }

      const formData = new FormData();

      formData.append("name", name);

      if (photoBlob) {
        formData.append("photo", photoBlob);
      }

      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/my`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      const text = await response.text();

      if (!response.ok) {
        setError(text);
        return;
      }

      navigate(to ? to : '/');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 100;
          const maxHeight = 100;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => {
              setImageSrc(URL.createObjectURL(blob)); // Preview resized image
              setPhotoBlob(blob); // Store the Blob to upload later
            },
            "image/jpeg",
            0.8 // '0.8' is quality (80%)
          );
        };
      };
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      {activeStep === 0 && (
        <Stack spacing={1} justifyContent="center" height='75vh'>
          <Typography variant="h4">Введите номер телефона</Typography>
          <form onSubmit={handleSendCode}>
            <PhoneInput required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            {error && <Alert severity="error">{error}</Alert>}
            <Button fullWidth variant='contained' type="submit" disabled={loading}>
              Продолжить
            </Button>
          </form>
          <Typography variant='body2'>
            На сайте используются куки-файлы. Нажимая на кнопку Продолжить, вы соглашаетесь с&nbsp;
            <Link href="/privacy">Политикой конфиденциальности</Link>.
          </Typography>
        </Stack>
      )}

      {activeStep === 1 && (
        <Stack spacing={1} justifyContent="center" height='75vh'>
          <Typography variant="h4">Введите CMC-код</Typography>
          <form onSubmit={handleVerifyCode}>
            <TextField
              value={verificationCode}
              type="number"
              label="Код"
              required
              fullWidth
              margin="normal"
              onChange={(e) => setVerificationCode(e.target.valueAsNumber)} />
            {error && <Alert severity="error">{error}</Alert>}

            <Button fullWidth variant='contained' type="submit" disabled={loading}>
              Продолжить
            </Button>
          </form>

          <Typography variant='caption' alignContent='center' fullWidth>
            <Link href="https://t.me/profisfera_ru">
              Написать в поддержку
            </Link>
          </Typography>
        </Stack>
      )}

      {activeStep === 2 && (
        <Stack spacing={1} justifyContent="center" height='75vh' >
          <Typography variant="h4">Введите имя</Typography>
          <form onSubmit={handleProfileUpdate}>
            <Stack alignItems='center' spacing={1}>
              <Avatar
                src={imageSrc}
                sx={{ bgcolor: 'primary.light', width: 100, height: 100 }}
              />
              <Stack direction='row' spacing={1}>
                <Typography variant="body2">Выбрать фото:</Typography>
                <input type="file" accept="image/*" onChange={handleFileChange} />
              </Stack>
            </Stack>
            <TextField
              value={name}
              margin="normal"
              required
              fullWidth
              label="Имя"
              onChange={(e) => setName(e.target.value)} />
            {error && <Alert severity="error">{error}</Alert>}

            <Button fullWidth variant='contained' type="submit" disabled={loading}>
              Продолжить
            </Button>
          </form>
        </Stack>
      )}

      <MobileStepper
        variant="dots"
        steps={3}
        position="static"
        activeStep={activeStep}
        sx={{ flexGrow: 1 }}
        backButton={
          <Button variant='contained' onClick={handleBack} disabled={activeStep === 2}>
            <KeyboardArrowLeft />
            Назад
          </Button>
        }
      />

    </Container>
  );
};

export default Login;
