import { Alert, Avatar, Button, Card, CardActions, CardContent, CardHeader, Link, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchListing } from '../slices/listingsSlice';
import { useState } from "react";
import { jwtDecode } from 'jwt-decode';

const Offers = ({ listing }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = localStorage.getItem('authToken');
  const userId = authToken && jwtDecode(authToken).sub;
  const [error, setError] = useState(null);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');

  const handleAcceptOffer = async (offerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/offers/${offerId}/accept`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error(await response.text());
      }

      dispatch(fetchListing({ id: listing.id, authToken, navigate }));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAddOffer = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/offers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ description, price, listingId: listing.id }),
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error(await response.text());
      }

      dispatch(fetchListing({ id: listing.id, authToken, navigate }));
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Typography variant="h4" gutterBottom>Заявки</Typography>

      {!userId && (<>
        <Typography variant="h5" gutterBottom>Подать заявку</Typography>
        <Typography>Чтобы подать заявку, пожалуйста, <Link href={"/login?to=" + encodeURI('/listing/' + listing.id)}>войдите</Link></Typography>
      </>)}

      {userId && listing.offers &&
        !listing.hasAcceptedOffer &&
        !listing.offers.some(o => o.creatorId === userId) &&
        listing.creatorId !== userId &&
        (
          <Card>
            <CardHeader title="Подать заявку" />
            <CardContent>
              <form onSubmit={handleAddOffer}>
                <TextField
                  label="Описание"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
                />
                <TextField
                  label="Цена"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
                {error && <Alert severity="error">{error}</Alert>}

                <Typography variant='body2' align="center" sx={{ mb: 2 }}>
                  Нажимая на кнопку ниже, вы соглашаетесь с&nbsp;
                  <Link href="/privacy">Политикой конфиденциальности</Link>.
                </Typography>

                <Button variant='contained' type="submit">Отправить заявку</Button>
              </form>
            </CardContent>
          </Card>
        )}

      {listing.offers?.length > 0 ? (
        [...listing.offers].sort((a, b) => (a.ratings ?? 0) - (b.ratings ?? 0)).map((offer) => (
          <Card key={offer.id}>
            <CardHeader
              avatar={<Avatar
                sx={{ bgcolor: 'primary.light' }}
                src={offer.photoUrl}
                alt={`${offer.name}`} />}

              title={<Link href={`/user/${offer.creatorId}`} >{offer.name}</Link>}
              subheader={offer.ratingsCount > 0
                ? `Рекомендуют ${offer.avarageRating.toFixed(2) * 100}% (${offer.ratingsCount})`
                : 'Нет отзывов'
              }
              action={<Typography variant="caption" color="text.secondary">
                {new Date(offer.createdAt).toLocaleDateString()}
              </Typography>}
            />
            <CardContent>
              <Typography variant="subtitle1">{offer.price} руб.</Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
              {(offer.isAccepted && <Alert sx={{ mt: 1 }} severity="info">Заявка принята</Alert>)}
            </CardContent>
            {offer.phoneNumber && <CardActions>
              <Link href={`tel:${offer.phoneNumber}`} variant="body1">{offer.phoneNumber}</Link>
              <Link href={`https://wa.me/${offer.phoneNumber}?text=Из%20profisfera.ru/listing/${listing.id}.%20`} variant="body1">WhatsApp</Link>
              {listing.creatorId === userId && ((
                !listing.isClosed &&
                !listing.offers.some(o => o.isAccepted)) &&
                <Button variant='contained' onClick={() => handleAcceptOffer(offer.id)}>Принять</Button>)}
            </CardActions>}
          </Card>
        ))
      ) : (
        <Typography>Заявок пока нет</Typography>
      )}
    </>
  );
}

export default Offers;