// components/Navbar.js
import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom';
const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});
const Navbar = () => {
  const location = useLocation();

  if (location.pathname.startsWith('/login'))
    return <></>;

  return (
    <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar>
        <Box sx={{ flexGrow: 0.25 }} />
        <IconButton color="inherit" size="large" href="/" >
          <ListIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        {!location.pathname.startsWith('/create-listing') && (
          <StyledFab color="secondary" href='/create-listing' >
            <AddIcon color='secondary.contrastText' />
          </StyledFab>
        )}
        <IconButton color="inherit" size="large" href="/profile" >
          <PersonIcon />
        </IconButton>
        <Box sx={{ flexGrow: 0.25 }} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
