// utils/pushy.js
import Pushy from 'pushy-sdk-web';

export const verifyPushyRegistration = async (navigate) => {
  // Register visitor's browser for push notifications
  await Pushy.register({ appId: process.env.REACT_APP_PUSHY_APP_ID }).then(async function (deviceToken) {
    // Print device token to console
    console.log('Pushy device token: ' + deviceToken);

    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login');
      return;
    }

    let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/devicetokens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({ token: deviceToken }),
    });

    if (response.status === 401) {
      navigate('/login');
      return;
    }

    if (!response.ok) {
      throw new Error('Failed to register the device token');
    }

    await Pushy.subscribe('notifications');
  })
};