// pages/EditProfile.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Alert, TextField, Button, Stack, Avatar } from '@mui/material';

const EditProfile = () => {

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [photoBlob, setPhotoBlob] = useState(null); // Store the resized image as a Blob
  const [imageSrc, setImageSrc] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login');
      return;
    }

    const fetchProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/my`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setName(data.name);
          setImageSrc(data.photoUrl);
        } else {
          throw new Error('Не удалось получить профиль');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/login');
        return;
      }

      const formData = new FormData();

      formData.append("name", name);

      if (photoBlob) {
        formData.append("photo", photoBlob);
      }

      const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/my`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      const text = await response.text();

      if (!response.ok) {
        setError(text);
        return;
      }

      navigate('/profile');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 100;
          const maxHeight = 100;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => {
              setImageSrc(URL.createObjectURL(blob)); // Preview resized image
              setPhotoBlob(blob); // Store the Blob to upload later
            },
            "image/jpeg",
            0.8 // '0.8' is quality (80%)
          );
        };
      };
    }
  };


  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Typography variant="h3" gutterBottom>Профиль</Typography>

      <form onSubmit={handleProfileUpdate}>
        <Stack alignItems='center' spacing={1}>
          <Avatar
            src={imageSrc}
            sx={{ bgcolor: 'primary.light', width: 100, height: 100 }}
          />
          <Stack direction='row' spacing={1}>
            <Typography variant="body2">Выбрать фото:</Typography>
            <input type="file" accept="image/*" onChange={handleFileChange} />
          </Stack>
        </Stack>

        <TextField
          value={name}
          margin="normal"
          required
          fullWidth
          label="Имя"
          onChange={(e) => setName(e.target.value)} />

        {error && <Alert severity="error">{error}</Alert>}

        <Button fullWidth variant='contained' type="submit" disabled={loading}>
          Сохранить
        </Button>
      </form>

    </Container>
  );
};

export default EditProfile;
