// pages/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Stack, CircularProgress, Avatar, Container, Alert, CardHeader } from '@mui/material';

const User = () => {
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/${userId}`);

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setData(data);
        } else {
          throw new Error('Failed to fetch ratings');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchRatings();
    }
  }, [navigate, userId]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Typography variant="h4" gutterBottom>Отзывы</Typography>
        <Stack alignItems="center" justifyContent="center" height="100vh">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (

    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Typography variant="h4" gutterBottom>Отзывы</Typography>
      <Stack spacing={2}>
        {error && <Alert severity="error">{error}</Alert>}

        <Stack direction={'row'} alignItems="center" spacing={1}>
          <Avatar
            sx={{ bgcolor: 'primary.light' }}
            src={data?.photoUrl}
            alt={`${data?.name}`}
          />
          <Typography variant="subtitle1" color="text.primary">
            {data?.name}
            {/* <br /> */}
            {/* Рекомендуют {avarageRating.toFixed(2) * 100}% ({ratingsCount}) */}
          </Typography>
        </Stack>

        {data?.ratings.length > 0 ? (
          data.ratings.map((rating) => (
            <Card key={rating.createdAt}>
              <CardHeader
                avatar={<Avatar
                  sx={{ bgcolor: 'primary.light' }}
                  src={rating.reviewerPhotoUrl}
                  alt={`${rating.reviewerName}`} />}

                title={`${rating.reviewerName}`}
                subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                action={<Typography variant="caption" color="text.secondary">
                  {new Date(rating.createdAt).toLocaleDateString()}
                </Typography>}
              />
              <CardContent>
                <Typography variant="body1">{rating.comment}</Typography>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography>Нет отзывов</Typography>
        )}
      </Stack>
    </Container>
  );
}

export default User;