// pages/Profile.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Button, Typography, Stack, Box, Tabs, Tab, CircularProgress, Avatar, Container, Alert, CardActionArea, CardMedia, Link, IconButton } from '@mui/material';
import Pushy from 'pushy-sdk-web';
import { verifyPushyRegistration } from '../utils/pushy';
import EditIcon from '@mui/icons-material/Edit';

const Profile = () => {
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const [registered, setRegistered] = useState(Pushy.isRegistered());
  const [pushyToken, setPushyToken] = useState(localStorage.getItem('pushyToken'));

  useEffect(() => {
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login');
      return;
    }

    const fetchProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BE_URL}/be/users/my`,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
            },
          }
        );

        if (response.status === 401) {
          navigate('/login');
          return;
        }

        if (response.ok) {
          const data = await response.json();
          setProfile(data);
        } else {
          throw new Error('Не удалось получить профиль');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Typography variant="h3">Профиль</Typography>
        <Stack alignItems="center" justifyContent="center" height="100vh">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Typography variant="h3" gutterBottom>Профиль</Typography>

      {error && <Alert severity="error">{error}</Alert>}

      <Stack direction={'row'} alignItems="center" spacing={1}>
        <Avatar
          sx={{ bgcolor: 'primary.light' }}
          src={profile.photoUrl}
          alt={`${profile.name}`}
        />
        <Stack>
          <Typography variant="subtitle1" color="text.primary">
            {profile.name}
          </Typography>
          {/*
            <Typography variant="subtitle1" color="text.primary">
              Баланс: {profile.balance} руб.
            </Typography>
            Рекомендуют {avarageRating.toFixed(2) * 100}% ({ratingsCount}) */}
          <Link href="https://t.me/profisfera_ru">
            Написать в поддержку
          </Link>
        </Stack>
        <Box sx={{ flex: 1 }} />
        <IconButton aria-label="edit" onClick={() => navigate(`/edit-profile`)} >
          <EditIcon />
        </IconButton>
        <Button variant='contained' onClick={() => navigate('/login')}>
          Выйти
        </Button>
      </Stack>

      <Tabs
        sx={{ mb: 1 }}
        value={tabIndex}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto">
        <Tab label="Мои объявления" />
        <Tab label="Мои заявки" />
        {/* <Tab label="Для технарей" /> */}
      </Tabs>

      {tabIndex === 0 && (
        <Stack spacing={2}>
          {profile.listings.length > 0 ? (
            profile.listings.map((listing) => (

              <Card
                key={listing.id}
                onClick={() => navigate(`/listing/${listing.id}`)}>

                <CardActionArea>
                  {
                    listing.photos.length !== 0 &&
                    <CardMedia
                      component="img"
                      image={`${process.env.REACT_APP_BE_URL}${listing.photos[0].filePath}`}
                      alt={listing.description}
                      sx={{ height: 240 }}
                    />
                  }

                  <CardContent>
                    <Typography variant="h6" color="text.primary">
                      {listing.title}
                    </Typography>
                    <Stack direction={'row'} alignItems="baseline" spacing={1} >
                      {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                        {listing.askedPrice} руб.
                      </Typography>}
                      <Typography variant="body2" color="text.secondary">
                        {listing.location}
                      </Typography>
                    </Stack>

                    <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
                      {listing.description}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {listing.isClosed ? 'Объявление закрыто' : 'Объявление открыто'}
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {listing.hasAcceptedOffer ? 'Заявка принята' : 'Заявка не принята'}
                    </Typography>

                    <Typography variant="caption" color="text.secondary">
                      {new Date(listing.createdAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body1">Нет объявлений</Typography>
          )}
        </Stack>
      )}

      {tabIndex === 1 && (
        <Stack spacing={2}>
          {profile.offers.length > 0 ? (
            profile.offers.map((offer) => (
              <Card key={offer.id} onClick={() => navigate(`/listing/${offer.listingId}`)}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="subtitle1">{offer.price} руб</Typography>
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {offer.isAccepted ? 'Заявка принята' : 'Заявка не принята'}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(offer.createdAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          ) : (
            <Typography variant="body1">Нет заявок</Typography>
          )}
        </Stack>
      )}

      {tabIndex === 2 && (
        <Stack spacing={1}>
          <Button variant='contained' onClick={() =>
            verifyPushyRegistration(navigate).then(() => {
              setRegistered(Pushy.isRegistered());
              setPushyToken(localStorage.getItem('pushyToken'));
            }).catch((error) => {
              setError(error.message);
            })
          }>Включить уведомления</Button>
          {registered && <Typography variant="body1">Уведомления включены</Typography>}
          <Typography variant="body1">{pushyToken}</Typography>
        </Stack>
      )}
    </Container >
  );
};

export default Profile;
