// pages/CreateListing.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Container, Alert, FormControlLabel, RadioGroup, Radio, Stack, CircularProgress, Link, Checkbox, FormGroup } from '@mui/material';
import { useSelector } from 'react-redux';

const CreateListing = () => {
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [isProvider, setIsProvider] = useState(false);
  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(true);
  const [location, setLocation] = useState('');
  const [askedPrice, setAskedPrice] = useState('');
  const [photos, setPhotos] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const listing = useSelector((state) =>
    state.listings.listings.find((l) => l.id === id)
  );

  useEffect(() => {
    let authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/login?to=' + encodeURI(id ? `/create-listing/${id}` : '/create-listing'));
    }
  }, [navigate, id]);

  useEffect(() => {
    if (listing) {
      setTitle(listing.title);
      setDescription(listing.description);
      setIsProvider(listing.isProvider);
      setIsPhoneNumberVisible(listing.isPhoneNumberVisible);
      setLocation(listing.location);
      setAskedPrice(listing.askedPrice || '');
    }
  }, [listing]);

  const MAX_TOTAL_FILE_SIZE = 16 * 1024 * 1024;

  const handleFileChange = (event) => {
    const files = event.target.files;
    let totalSize = 0;

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
    }

    if (totalSize > MAX_TOTAL_FILE_SIZE) {
      setError('Общий размер файлов не должен превышать 16 МБ. Некоторые файлы не будут загружены.');
    } else {
      setError(null);
      setPhotos(files);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/login');
        return;
      }

      const formData = new FormData();

      formData.append("title", title);
      formData.append("description", description);
      formData.append("askedPrice", askedPrice);
      formData.append("location", location);
      formData.append("isProvider", isProvider);
      formData.append("isPhoneNumberVisible", isPhoneNumberVisible);

      for (let i = 0; i < photos.length; i++) {
        formData.append("photos", photos[i]);
      }

      let response = await fetch(`${process.env.REACT_APP_BE_URL}/be/listings/` + (id ? id : ''), {
        method: (id ? 'PUT' : 'POST'),
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (response.status === 401) {
        navigate('/login');
        return;
      }

      if (!response.ok) {
        const text = await response.text();
        setError(text);
        return;
      }

      const responseData = await response.json();
      navigate(`/listing/${responseData.id}`);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Typography variant="h3">{listing ? 'Обновить объявление' : 'Создать новое объявление'}</Typography>
        <Stack alignItems="center" justifyContent="center" height="70vh">
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }} >
      <Typography variant="h3" gutterBottom>{listing ? 'Обновить объявление' : 'Создать новое объявление'}</Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <RadioGroup
            value={isProvider ? 'true' : 'false'}
            name="radio-buttons-group"
            onChange={(e) => setIsProvider(e.target.value === 'true')}>
            <FormControlLabel value="false" control={<Radio />} label="Воспользуюсь услугой" />
            <FormControlLabel value="true" control={<Radio />} label="Предоставляю услугу" />
          </RadioGroup>

          <TextField
            fullWidth
            label="Название услуги"
            value={title}
            multiline
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label="Описание"
            value={description}
            multiline
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Местоположение"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <TextField
            fullWidth
            label="Цена (необязательно)"
            type="number"
            value={askedPrice}
            onChange={(e) => setAskedPrice(e.target.value)}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={isPhoneNumberVisible}
                onChange={(e) => setIsPhoneNumberVisible(e.target.checked)} />}
              label="Показывать номер телефона всем" />
          </FormGroup>

          <Typography variant="body1" gutterBottom>Фотографии</Typography>
          <input type="file" accept="image/*" multiple onChange={handleFileChange} />

          {listing && photos.length === 0 &&
            <Alert severity="info" >Фотографии не изменены</Alert>
          }

          {error && <Alert severity="error" >{error}</Alert>}

          <Typography variant='body2' align="center">
            Нажимая на кнопку ниже, вы соглашаетесь с&nbsp;
            <Link href="/privacy">Политикой конфиденциальности</Link>.
          </Typography>

          <Button type="submit" fullWidth variant='contained'>
            {listing ? 'Обновить объявление' : 'Создать объявление'}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default CreateListing;
